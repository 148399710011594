<template>
  <div>
    <h2 class="th-title">Tingkat</h2>
    <a-card>
      <div class="d-flex justify-content-between mb-3">
        <a-input placeholder="Masukan Nama Kategori" style="width: 200px" v-model:value="search" @change="handleSearchCategory">
          <template #prefix>
            <span>
              <search-outlined style="color: rgba(0, 0, 0, 0.25)" />
            </span>
          </template>
        </a-input>
        <a-button @click.prevent="showModalAdd">
          <template #icon><plus-outlined /></template>
          Tambahkan Tingkat
        </a-button>
        <a-modal v-model:visible="modalAddVisible" title="Tambahkan Kategori" @ok="handleOk">
          <a-form layout="vertical" :model="formAdd">
            <a-form-item required label="Nama" name="name" v-bind="validateInfos.name">
              <a-input placeholder="Masukkan nama kategori" v-model:value="formAdd.name" />
            </a-form-item>
            <a-form-item label="Deskripsi">
              <a-textarea
                :rows="6"
                placeholder="Masukkan deskripsi (opsional)"
                v-model:value="formAdd.description"
                show-count
                :maxlength="100"
              />
            </a-form-item>
          </a-form>
          <template #footer>
            <a-button @click.prevent="confirmAdd" key="submit" type="primary" html-type="submit"
              >Tambahkan</a-button
            >
          </template>
        </a-modal>
      </div>
      <div>
        <a-table :columns="columns" :data-source="data" bordered>
          <template #name="{ text, record }">
            <a-form :model="formEdit" layout="vertical" v-if="record.key === idEdit">
              <a-form-item name="name" v-bind="validateInfosEdit.name">
                <a-input v-model:value="formEdit.name" />
              </a-form-item>
            </a-form>
            <span v-else>
              {{ text }}
            </span>
          </template>
          <template #description="{ text, record }">
            <span v-if="record.key === idEdit">
              <a-textarea v-model:value="formEdit.description" />
            </span>
            <span v-else>
              {{ text || '-' }}
            </span>
          </template>
          <template #action="{ record }">
            <span v-if="record.key === idEdit">
              <a @click.prevent="confirmEdit(record)">
                <check-outlined></check-outlined>
              </a>
              <a-divider type="vertical" />
              <a @click.prevent="closeEdit">
                <close-outlined></close-outlined>
              </a>
            </span>
            <span v-else>
              <a @click.prevent="categoryAction(record, 'UPDATE')">Ubah</a>
              <a-divider type="vertical" />
              <a @click.prevent="categoryAction(record, 'DELETE')">Hapus Kategori</a>
            </span>
          </template>
        </a-table>
      </div>
    </a-card>
  </div>
</template>

<script>
import { onMounted, reactive, ref, toRaw } from 'vue';
import { useStore } from 'vuex';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons-vue'
import { Modal, notification } from 'ant-design-vue';
import { ExclamationCircleOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons-vue';
import { createVNode } from 'vue';
import { useForm } from '@ant-design-vue/use';

const columns = [
  {
    title: 'Nama',
    dataIndex: 'name',
    key: 'name',
    slots: { customRender: 'name' },
  },
  {
    title: 'Deskripsi',
    dataIndex: 'description',
    key: 'description',
    slots: { customRender: 'description' },
  },
  {
    title: 'Aksi',
    key: 'action',
    slots: { customRender: 'action' },
    align: 'center',
  },
];

export default {
  components: {
    SearchOutlined,
    PlusOutlined,
    CheckOutlined,
    CloseOutlined,
  },
  setup() {
    const store = useStore()
    let data = ref([])
    let search = ref(null)
    let modalAddVisible = ref(false)
    let formAdd = reactive({
      name: null,
      description: null,
    })
    let formEdit = ref({
      name: null,
      description: null,
    })
    let idEdit = ref(null)
    const loadingAdd = ref(false)
    const rulesRef = reactive({
      name: [
        {
          required: true,
          message: 'Nama harus diisi!',
          trigger: 'change',
        },
      ],
    })

    onMounted(() => {
      fetchCategory()
    })

    const fetchCategory = async (search = '') => {
      const rawData = await store.dispatch('category/FETCH_CATEGORY', search)
      data.value = rawData.map(cat => {
        return {
          key: cat._id,
          name: cat.name,
          description: cat.description,
          ...cat,
        }
      })
    }

    const showModalAdd = () => {
      modalAddVisible.value = true
    }

    const handleOk = () => {
      modalAddVisible.value = false
    }

    const closeEdit = () => idEdit.value = null

    const { validate:validateEdit, validateInfos:validateInfosEdit } = useForm(formEdit, rulesRef);
    const confirmEdit = async (record) => {
      try {
        const payload = {
          id: record.key,
          data: {
            name: formEdit.value.name,
            description: formEdit.value.description,
          },
        }
        const tes = await validateEdit()
        // const tes2 = await validate()
        Modal.confirm({
          title: `Apa anda yakin ingin mengubah data pada kategori '${record.name}'?`,
          icon: createVNode(ExclamationCircleOutlined),
          async onOk() {
            try {
              const data = await store.dispatch('category/UPDATE_CATEGORY', payload)
              modalAddVisible.value = false
              fetchCategory()
              notification.success({
                message: `Berhasil Mengubah Data Pada Kategori '${record.name}'`,
              })
              idEdit.value = null
            } catch (err) {
              console.log(err)
            }
          },
          onCancel() {},
        })
      } catch (err) {
        console.log(err)
      }
    }

    const { resetFields, validate, validateInfos } = useForm(formAdd, rulesRef);

    const confirmAdd = async () => {
      try {
        await validate()
        // console.log(console.log(toRaw(formAddRef)))
        Modal.confirm({
          title: 'Apa anda yakin ingin menambahkan kategori?',
          icon: createVNode(ExclamationCircleOutlined),
          async onOk() {
            try {
              const data = await store.dispatch('category/ADD_CATEGORY', { ...formAdd })
              modalAddVisible.value = false
              fetchCategory()
              notification.success({
                message: `Berhasil Menambahkan '${formAdd.name}' Dari Daftar Kategori`,
              })
              resetFields()
            } catch (err) {
              console.log(err)
            }
          },
          onCancel() {},
        })
      } catch(err) {}
    }

    const categoryAction = async (record, action) => {
      const data = {
        id: record.key,
        data: {
          name: record.name,
          description: record.description,
        },
      }
      if (action === 'DELETE') {
        Modal.confirm({
          title: `Apa anda yakin ingin menghapus kategori '${record.name}' dari daftar?`,
          icon: createVNode(ExclamationCircleOutlined),
          async onOk() {
            try {
              const res = await store.dispatch('category/DELETE_CATEGORY', { ...data })
              fetchCategory()
              notification.success({
                message: `Berhasil Menghapus '${record.name}' Dari Daftar Kategori`,
              })
            } catch (err) {
              console.log(err)
            }
          },
          onCancel() {},
        })
      } else if (action === 'UPDATE') {
        idEdit.value  = record.key
        formEdit.value = {
          name: record.name,
          description: record.description || null,
        }
        // const data = await store.dispatch(action + '_CATEGORY', { id, data })
      }
    }

    const handleSearchCategory = () => {
      fetchCategory(search.value)
    }

    return {
      data,
      columns,
      search,
      modalAddVisible,
      showModalAdd,
      handleOk,
      formAdd,
      rulesRef,
      loadingAdd,
      confirmAdd,
      validate,
      validateInfos,
      validateEdit,
      validateInfosEdit,
      categoryAction,
      idEdit,
      formEdit,
      closeEdit,
      confirmEdit,
      handleSearchCategory,
    }
  },
}
</script>
